<template>
    <v-container
            class="fill-height"
            fluid
    >
        <v-row
                align="center"
                justify="center"
        >
            <v-col
                    cols="12"
                    sm="8"
                    md="4"
            >
                <Error :error="error"/>
                <v-card class="elevation-12">
                    <v-toolbar
                            :color="primaryColor"
                            dark
                            flat
                    >
                        <v-toolbar-title>Please login to access this resource</v-toolbar-title>
                        <v-spacer/>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                    label="Username"
                                    name="username"
                                    prepend-icon="person_pin"
                                    type="text"
                                    v-model="username"
                            />

                            <v-text-field
                                    id="password"
                                    label="Password"
                                    name="password"
                                    prepend-icon="lock"
                                    type="password"
                                    v-model="password"
                                    v-on:keyup.enter="submit()"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="primary" @click="submit()">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import store from '@/store/store.js'
    import Error from '@/views/Error.vue'

    export default {
        name: 'Login',
        components: {Error},
        data: () => ({
            username: "",
            password: "",
            $store: store,
            error: null,
            primaryColor: "blue-grey darken-2"
        }),
        created() {
            this.$vuetify.theme.dark = true
            switch (this.$route.params.reason) {
                case "NOTLOGGEDIN":
                    this.error = "You need to be logged in to access this page.";
                    break
                case "NOTADMIN":
                    this.error = "You need to be an administrator to access this page.";
                    break
                case undefined:
                    this.error = ""
            }
        },
        methods: {
            submit() {
                this.$store.dispatch('login', {
                    username: this.username,
                    password: this.password
                })
                    .then(() => {
                        if (this.$route.params.nextUrl != null) {
                            this.$router.push(this.$route.params.nextUrl)
                        } else {
                            this.$router.push('search')
                        }
                    })
                    .catch(err => {
                            if (err.response.status === 403) {
                                this.error = "Login error: invalid user or password"
                            } else {
                                this.error = "Login error: Network connectivity issue on our side"
                            }
                        }
                    )
            }
        }
    }
</script>

<style scoped>

</style>